<template>
     <div class="container mt-4">
        <div v-if="messageAlert">
            <Notification :message="messageAlert" @statusAlertMsg="cambiarMsgAlert" />
        </div>
        <br>
        <div class="row">
                <div class="col-12">
                    <div class="row mb-4">
                        <div class="col-12">
                            <h2 >Administra tus indicadores - {{categoria.descripcion}}</h2>
                            <p>Selecciona el indicador para visualizar y editar tus registros.</p>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div v-for="dato in datos.data || []" :key="dato.id" class="col-6 col-md-4 col-lg-3 mb-4">
                            <a class="boton-caja">
                            <div class="caja xs mh mb-2">
                                <h5>{{dato.titulo}}</h5>
                                <div class="arrow-right">
                                    <a class="text-decoration-none me-2" @click="openModalEdit(dato)" href="#" title="Editar"><i class="ri-pencil-line ri-lg"></i></a>
                                    <a v-if="dato.id<=10" class="text-decoration-none me-2" :href="'/admin/'+dato.ruta" title="Registros"><i class="ri-file-list-3-line ri-lg"></i></a>
                                    <a v-if="dato.id>=11" class="text-decoration-none me-2" :href="'/admin/indicador/'+dato.ruta+'/'+dato.categoria_id+'/'+dato.id" title="Registros"><i class="ri-file-list-3-line ri-lg"></i></a>
                                    <a class="text-decoration-none me-2" v-if="dato.archivo && dato.carpeta" href="#" @click.prevent="downloadWithVueResource(dato)" :title="dato.archivo"><i class="ri-file-excel-line ri-lg"></i></a>
                                </div>
                            </div>
                            </a>
                        </div>
                    </div>
                    <div class="row" v-if="contadorArreglo == 0">
						<div class="col-6 col-md-4 col-lg-3 ">
							<div class="caja xs empty">
								<h4>Aún no tienes registros</h4>
							</div>
						</div>
					</div>
                    <div class="row" v-if="contadorArreglo>0">
                        <div class="col">
                            <nav>
                                <ul class="pagination">
                                    <li class="page-item mr-2" v-if="pagination.current_page > 1" >
                                        <a href="#" class="button-pagination page-link" @click.prevent="changePage(pagination.current_page - 1)">
                                            <span>Anterior</span>
                                        </a>
                                    </li>
                                    <li class="page-item mr-2" v-if="pagination.current_page == 1" >
                                        <a class="button-pagination page-link disabled">
                                            <span>Anterior</span>
                                        </a>
                                    </li>
                                    <li class="page-item mr-2" v-for="page in pagesNumber" :key="page">
                                        <a href="#" :class="[page == isActive ? 'active' : '']" class="button-pagination page-link" @click.prevent="changePage(page)">
                                        {{page}}
                                        </a>
                                    </li>
                                    <li class="page-item mr-2" v-if="pagination.current_page < pagination.last_page">
                                        <a href="#" class="button-pagination page-link" @click.prevent="changePage(pagination.current_page + 1)">
                                            <span>Siguiente</span>
                                        </a>
                                    </li>
                                    <li class="page-item mr-2" v-if="pagination.current_page == pagination.last_page" >
                                        <a class="button-pagination page-link disabled">
                                            <span>Siguiente</span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
     </div>
    <div class="modal fade" id="modal_add" aria-labelledby="addModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="addModalLabel">{{titleModal}} indicador</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="reset()"></button>
                </div>
                <div class="modal-body">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <label for="titulo">Título: <span class="text-danger small">*</span></label>
                            <input @click="validateInput(1)" class="form-control" type="text" name="titulo" id="titulo" v-model="data.titulo"/>
                            <small style="color:#FF6868;" v-for="(e_titulo, index) in errors.titulo" :key="index">{{e_titulo}}</small>
                        </div>
                        <div class="col-sm-6">
                            <label for="archivo">Archivo: </label>
                            <input type="file" class="form-control" name="archivo" id="archivo" ref="fileInput" @input="onSelectFile" accept=".xls,.XLS,.xlsx,.XLSX"/>
                            <small style="color:#FF6868;" v-for="(e_file, index) in errors.file" :key="index">{{e_file}}</small>
                        </div>
                        
                    </div>
                    <div class="row mb-2  justify-content-center align-items-center">
                        <div class="col-sm-6">
                            <label for="meta">Meta: <span class="text-danger small">*</span></label>
                            <input @click="validateInput(2)" class="form-control" type="text" name="meta" id="meta" v-model="data.meta"/>
                            <small style="color:#FF6868;" v-for="(e_meta, index) in errors.meta" :key="index">{{e_meta}}</small>
                        </div>

                        <div class="col-sm-6 text-center">
                            <a v-if="data.archivo && data.carpeta" href="#" @click.prevent="downloadWithVueResource(data)" style="text-decoration:none">Descarga el archivo</a>
                            <span v-if="data.archivo==null">No se ha cargado archivo</span>
                        </div>

                    </div>
                    <div class="row mb-2">
                        <div class="col-sm-12">
                            <label for="definicion">Definicion: <span class="text-danger small">*</span></label>
                            <ckeditor @click="validateInput(3)" :editor="editor" v-model="data.definicion" :config="editorConfig"></ckeditor>
                            <small style="color:#FF6868;" v-for="(e_definicion, index) in errors.definicion" :key="index">{{e_definicion}}</small>
                        </div>
                        
                    </div>
                    <div class="row mb-2">
                        <div class="col-sm-12">
                            <label for="relevancia">Relevancia: <span class="text-danger small">*</span></label>
                            <ckeditor @click="validateInput(4)" :editor="editor" v-model="data.relevancia" :config="editorConfig"></ckeditor>
                            <small style="color:#FF6868;" v-for="(e_relevancia, index) in errors.relevancia" :key="index">{{e_relevancia}}</small>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-sm-12">
                            <label for="fuente">Fuente: <span class="text-danger small">*</span></label>
                            <input @click="validateInput(5)" class="form-control" type="text" name="fuente" id="fuente" v-model="data.fuente"/>
                            <small style="color:#FF6868;" v-for="(e_fuente, index) in errors.fuente" :key="index">{{e_fuente}}</small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <p class="small">
                            <span class="text-danger small">*</span> Datos requeridos
                            </p>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click="reset()" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                    <button @click="save()" type="button" class="btn btn-primary" v-text="option=='create'?'Guardar':'Guardar'"></button>
                </div>
            </div>
        </div>
    </div>     
</template>

<script>
    //Bootstrap and jQuery libraries
    import "bootstrap/dist/css/bootstrap.min.css";
    import "jquery/dist/jquery.min.js";
    import $ from "jquery";
    import axios from "axios";
    import Editor from 'ckeditor5-custom-build/build/ckeditor';

    import Notification from "./Notification";
    export default {
        components: {
            Notification
        },
        props:['categoria'],
        data() {
            return { 
                editor: Editor,
                editorData: '<p>Content of the editor.</p>',
                editorConfig: {
                },              
                titleModal:"",
                datos:[],
                errors:[],
                data: {
                    id: "",
                    titulo: "",
                    meta: "",
                    definicion: "",
                    relevancia: "",
                    fuente: "",
                    archivo:"",
                    carpeta:"",

                },
                data_archivo:"",
                addModal: "",
                option: "",
                data_id: "",
                data_name: "",
                dataTable: null,
                messageAlert:0,
                contadorArreglo:1,
                pagination:{
                    'total': 0,
                    'current_page': 0,
                    'per_page': 0,
                    'last_page': 0,
                    'from': 0,
                    'to': 0,
			    },
			    offset: 2,
                store_files: "",
                nameFile:"",
            }
        },
        methods:{
            forceFileDownload(response, file){
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', file) 
                document.body.appendChild(link)
                link.click()
            },   
            downloadWithVueResource(data){
                let url = '/files/'+data.carpeta+'/'+data.archivo;
                axios({
                    method: 'get',
                    url: url,
                    responseType: 'blob'
                })
                .then(response => {
                    this.forceFileDownload(response, data.archivo)  
                })
                .catch( error => console.log(error))
                
            },
            cambiarMsgAlert(value){
                this.messageAlert= value;
            },

            onSelectFile(event) {
                this.data_archivo = event.target.files[0];
                let reader = new FileReader();
                reader.addEventListener(
                    "load",
                    function () {
                        this.store_files = reader.result;
                    }.bind(this),
                    false
                );
                if (this.data_archivo) {
                    if ( this.data_archivo.size > 1024 * 1024 * 8){
                        this.errors = {
                            'file':['EL archivo debe tener un peso máximo de 8mb.'],
                        }
                        //this.mostrarErrores();
                        this.removeFile()
                        return
                    }     
                    if (/\.(xls|XLS|xlsx|XLSX)$/i.test(this.data_archivo.name)) {
                        this.nameFile = "Archivo cargado: "+this.data_archivo.name;
                        reader.readAsDataURL(this.data_archivo);
                    }else{
                        this.errors = {
                            'file':['El formato del archivo no es válido, solo se admite formato XLS o XLSX.'],
                        }
                        console.log(this.errors.file)
                        //this.mostrarErrores();
                        this.removeFile()
                        return
                    }
                }
            },

            removeFile() {
                this.data_archivo = "";
                this.store_files = "";
                document.getElementById("archivo").value = '';
                this.nameFile="";
                
            },

            getList(page=''){
                axios.post('/admin/listar-indicadores/'+this.categoria.id+'?page='+page).then(response=>{
                    this.datos = response.data.datos;
                    this.contadorArreglo = this.datos.data.length;
                    this.pagination = response.data.pagination;
                })
            },
            validateInput(value) {
                switch (value) {
                    case 1:
                        this.errors.titulo = "";
                    break;
                    case 2:
                        this.errors.meta = "";
                        break;
                    case 3:
                        this.errors.definicion = "";
                        break;
                    case 4:
                        this.errors.relevancia = "";
                        break;
                    case 5:
                        this.errors.fuente = "";
                        break;
                }
            },
            openModalAdd() {
                this.reset();
                this.option = "create";
                this.titleModal = "Agregar";
                this.addModal.show();
            },

            save() {
                const formData = new FormData();
                formData.append("titulo", this.data.titulo);
                formData.append("meta", this.data.meta);
                formData.append("definicion", this.data.definicion);
                formData.append("relevancia", this.data.relevancia);
                formData.append("fuente", this.data.fuente);
                formData.append("archivo", this.data_archivo);
                if (this.option == "create"){
                    axios.post('/admin/indicadores', formData).then(response => {
                        if(response && response.data.statusCode == 200){
                            this.messageAlert = response.data.message;
                            this.reset();
                            this.addModal.hide();
                            this.getList();
                        }
                        else{
                            console.log(response);
                        }
                    }).catch((error)=> {
                        if (error.response.status == 422){
                            this.errors = error.response.data.errors;
                        }else{
                            console.log(this.errors);
                        }
                    });
                }
                else if (this.option == "update"){
                    axios.post('/admin/indicador-update/'+this.data_id, formData,{
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(response => {
                        if(response && response.data.statusCode == 200){
                            this.messageAlert = response.data.message;
                            this.reset();
                            this.addModal.hide();
                            this.getList();
                        }
                        else{
                            console.log(response);
                        }
                    }).catch((error)=> {
                        if (error.response.status == 422){
                            this.errors = error.response.data.errors;
                        }else{
                            console.log(this.errors);
                        }
                    });
                }
            },
            openModalEdit(data) {
                this.reset();
                this.option = "update";
                this.titleModal = "Editar";
                this.data_id = data.id;
                this.data_name = data.titulo;
                this.data.id = data.id;
                this.data.titulo = data.titulo;
                this.data.meta = data.meta;
                this.data.definicion = data.definicion;
                this.data.relevancia = data.relevancia;
                this.data.fuente = data.fuente;
                this.data.archivo = data.archivo;
                this.data.carpeta = data.carpeta;
                this.addModal.show();
            },
            reset() {
                this.data_id = "";
                this.data_name = "";
                this.data.id = "";
                this.data.titulo = "";
                this.data.meta = "";
                this.data.definicion = "";
                this.data.relevancia = "";
                this.data.fuente = "";
                this.titleModal="";
                this.removeFile();
            },
            changePage(page){
                this.pagination.current_page = page;
                this.getList(page);
		    }
        },
        computed: {
            isActive(){
                return this.pagination.current_page;
            },
            pagesNumber(){
                if(!this.pagination.to){
                    return [];
                } 
                var from = this.pagination.current_page - this.offset;
                if(from < 1 ){
                    from = 1;
                }
                var to = from + (this.offset * 2);
                if (to >= this.pagination.last_page){
                    to = this.pagination.last_page;
                }
                var pageArray = [];
                while(from <= to){
                    pageArray.push(from);
                    from++;
                }
                return pageArray;
            },
        },
        mounted() {
            this.getList();
            this.addModal = new bootstrap.Modal(
            document.getElementById("modal_add")
            );
            var tooltipTriggerList = [].slice.call(
            document.querySelectorAll('[data-bs-toggle="tooltip"]')
            );
            var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new bootstrap.Tooltip(tooltipTriggerEl);
            });
        },
    }
</script>