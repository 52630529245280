<template>
    <div class="container">
      <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
              <div class="d-flex justify-content-center py-4">
                <a href="/" class="logo d-flex align-items-center w-auto">
                  <img src="img/logo-asivamostuxtla-1.png" alt="" width="100" height="60">
                </a>
              </div>
              <div class="card mb-3">
                <div class="card-body">
                  <div class="pt-4 pb-2">
                    <h5 class="card-title text-center pb-0 fs-4">Bienvenido</h5>
                    <p class="text-center small">Ingresa tu correo y contraseña para iniciar sesión</p>
                  </div>
                  <form>
                    <div class="form-floating mb-2">
                        <input class="form-control" required autofocus type="email" name="email" id="email" placeholder="name@example.com" v-model="login.email">
                        <label for="email">Correo electrónico</label>
                    </div>
                    <small style="color:red;" v-for="(e_email, index) in errors.email" :key="index">{{e_email}}</small>
                    <div class="form-floating mb-2">
                        <input class="form-control" required  type="password" name="password" id="password" placeholder="Password" v-model="login.password">
                        <label for="floatingPassword">Contraseña</label>
                    </div>
                    <small style="color:red;" v-for="(e_password, index) in errors.password" :key="index">{{e_password}}</small>
                    <small style="color:red;" v-for="(e_credential, index) in errors.credentials" :key="index">{{e_credential}}</small>
                    <div class="checkbox mb-3">
                    <label>
                        <input type="checkbox" id="remember" name="remember" v-model="login.remember"> Recuerda mi sesión
                    </label>
                    </div>
                    <button class="w-100 btn btn-lg btn-primary" @click.prevent="enviar">Iniciar Sesión</button>
                </form>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            errors:[],
            login:{
                email:'',
                password:'',
                remember:'',
            },
            invalid:{
                'email':0,
                'password':0,
                'credentials':0
            }
        }
    },
    methods: {
        enviar(){
            axios.post('login', this.login).then(response => {
                window.location.href = window.location.origin +'/admin';
            })
            .catch((error)=> {
                console.log(error);
                if (error.response.status == 422) {
                    this.errors = error.response.data.errors;
                    console.log(this.errors);
                }
            });
        },
    },
}
</script>
