<template>
    <div v-if="messageAlert">
        <Notification :message="messageAlert" @statusAlertMsg="cambiarMsgAlert" />
    </div>
    <div class="container mt-4">
        <h4>{{dataindicador.titulo}}</h4>
        <hr>
        <div class="row mt-2">
            <div class="col-sm-12">
                <table class="table table-striped dt-responsive nowrap" id="tableData">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Año/trimestre</th>
                            <th>Servicio de deuda</th>
                            <th>Ingreso de libre disposición</th>
                            <th>Porcentaje</th>
                            <th>Porcentaje</th>
                        </tr>
                    </thead>
                    <tfoot>
                        <tr>
                            <th></th>
                            <th>Año/trimestre</th>
                            <th>Servicio de deuda</th>
                            <th>Ingreso de libre disposición</th>
                            <th>Porcentaje</th>
                            <th>Porcentaje</th>
                        </tr>
                    </tfoot>
                    <tbody>
                        <tr v-for="dato in datos" :key="dato.id">
                            <td>
                                <a class="text-decoration-none me-2" @click="openModalEdit(dato)" href="#" title="Editar"><i class="ri-pencil-line"></i></a>
                                <a class="ms-2 text-decoration-none" @click="openModalDelete(dato)" href="#" title="Eliminar"> <i class="ri-delete-bin-line"></i></a>
                            </td>
                            <td>{{ dato.periodo }}</td>
                            <td>{{ new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(dato.servicio_de_deuda) }}</td>
                            <td>{{ new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(dato.ingresos_de_libre_disposicion) }}</td>
                            
                            <td>
                                <a class="btn-custom" :style="{background: dato.color +' 0% 0% no-repeat padding-box'}">
                                    {{ new Intl.NumberFormat('es-MX', { style: 'percent', minimumFractionDigits: 1,}).format(dato.porcentaje) }}
                                </a>
                            </td>
                            <td>{{ dato.porcentaje }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modal_add" aria-labelledby="addModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="addModalLabel">{{titleModal+ ' '+ dataindicador.titulo.toLowerCase()}} </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="reset()"></button>
                </div>
                <div class="modal-body">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <label for="periodo">Año/ Trimestre: <span class="text-danger small">*</span></label>
                            <input @click="validateInput(1)" class="form-control" type="text" name="periodo" id="periodo" v-model="data.periodo"/>
                            <small style="color:#FF6868;" v-for="(e_periodo, index) in errors.periodo" :key="index">{{e_periodo}}</small>
                        </div>
                        <div class="col-sm-6">
                            <label for="servicio_de_deuda">Servicio de deuda: <span class="text-danger small">*</span></label>
                            <input @click="validateInput(2)" class="form-control" type="number" step="0.01" name="servicio_de_deuda" id="servicio_de_deuda" v-model="data.servicio_de_deuda" v-on:keyup="inputKeydown"/>
                            <small style="color:#FF6868;" v-for="(e_servicio_de_deuda, index) in errors.servicio_de_deuda" :key="index">{{e_servicio_de_deuda}}</small>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <label for="ingresos_de_libre_disposicion">Ingreso de libre disposición: <span class="text-danger small">*</span></label>
                            <input @click="validateInput(3)" class="form-control"  type="number" step="0.01" name="ingresos_de_libre_disposicion" id="ingresos_de_libre_disposicion" v-model="data.ingresos_de_libre_disposicion" v-on:keyup="inputKeydown"/>
                            <small style="color:#FF6868;" v-for="(e_ingresos_de_libre_disposicion, index) in errors.ingresos_de_libre_disposicion" :key="index">{{e_ingresos_de_libre_disposicion}}</small>
                        </div>
                        <div class="col-sm-6">
                            <label for="porcentaje">Porcentaje: <span class="text-danger small">*</span></label>
                            <input @click="validateInput(4)" class="form-control" type="number" step="0.01" name="porcentaje" id="porcentaje" v-model="data.porcentaje" v-on:keyup="inputKeydownPercent"/>
                            <small style="color:#FF6868;" v-for="(e_porcentaje, index) in errors.porcentaje" :key="index">{{e_porcentaje}}</small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <p class="small">
                            <span class="text-danger small">*</span> Datos requeridos
                            </p>
                        </div>
                        <div class="col-sm-6">
                            <a class="btn-custom" :style="{background: porcentajeColor +' 0% 0% no-repeat padding-box'}">
                                {{ new Intl.NumberFormat('es-MX', { style: 'percent', minimumFractionDigits: 1,}).format(porcentajeValor) }}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click="reset()" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                    <button @click="save()" type="button" class="btn btn-primary" v-text="option=='create'?'Guardar':'Guardar'"></button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modal_delete" tabindex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="deleteUserModalLabel">
                        Eliminar {{dataindicador.titulo.toLowerCase()}}
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click.prevent="reset()"></button>
                </div>
                <div class="modal-body">
                    <p>¿Deseas eliminar el {{dataindicador.titulo.toLowerCase() + ': '}}<span class="fw-bold"> {{data_name}}</span>?</p>
                </div>
                <div class="modal-footer">
                    <button @click="reset()" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                    <button @click="deleteRow()" type="button" class="btn btn-danger">Eliminar</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modal_grafica" aria-labelledby="graficaModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="graficaModalLabel">{{dataindicador.titulo}}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-if="mostrarGrafica">
                        <!-- <indicador-grafica-component :info_grafica="info_grafica"></indicador-grafica-component> -->
                        <LineColumn :info_grafica="info_grafica" /> 
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Aceptar</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import JSZip from "jszip";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
window.JSZip = JSZip;
import $ from "jquery";
import axios from "axios";
import Notification from "./Notification";
import IndicadorGraficaComponent from  '../main/IndicadorGraficaComponent';
import LineColumn from  '../main/LineColumn';

export default {
    inject: ["datatablesConfig"],
    components: {
        Notification,
        IndicadorGraficaComponent,
        LineColumn
    },
    props:['dataindicador'],
    data() {
        return {
            mostrarGrafica:0,
            titleModal:"",
            info_grafica:[],
            datos:[],
            errors:[],
            data: {
                id: null,
                periodo: null,
                servicio_de_deuda: null,
                ingresos_de_libre_disposicion: null,
                porcentaje: null,                
            },
            addModal: "",
            deleteModal:"",
            graficaModal:"",
            option: "",
            data_id: "",
            data_name: "",
            dataTable: null,
            messageAlert:0,
            data_info:[],
            options: {
                money: {
                    reverse: true,
                    selectOnFocus: true
                }
            },
            porcentajeColor:"#fff",
            porcentajeValor:0,
        };
    },
    methods: {
        colorPercentage(value){
            let color;
            if(value <= 0.05 )
                color = "#00B783";
            else if(value >= 0.05 && value <= 0.1)
                color = "#E8D92E";
            else if(value > 0.1)
                color = "#FF6260";
            else
                color = "#fff";
            return color;
        },
        inputKeydownPercent(e){
            this.porcentajeValor = this.data.porcentaje;
            this.porcentajeColor = this.colorPercentage(this.porcentajeValor);
        },
        inputKeydown(e){
            this.data.porcentaje =  parseFloat(this.data.servicio_de_deuda) / parseFloat(this.data.ingresos_de_libre_disposicion);
            this.porcentajeValor = this.data.porcentaje;
            this.porcentajeColor = this.colorPercentage(this.porcentajeValor);
        },
        cambiarMsgAlert(value){
            this.messageAlert= value;
        },
        getList(){
            axios.get('/admin/listar-servicio-de-deuda').then(response=>{
                this.datos = response.data.datos;
                this.data_info = response.data.info_grafica;
            })
        },
        validateInput(value) {
            switch (value) {
                case 1:
                    this.errors.periodo = "";
                break;
                case 2:
                    this.errors.servicio_de_deuda = "";
                    break;
                case 3:
                    this.errors.ingresos_de_libre_disposicion = "";
                    break;
                case 4:
                    this.errors.porcentaje = "";
                    break;
            }
        },
        openModalAdd() {
            this.reset();
            this.option = "create";
            this.titleModal = "Agregar";
            this.addModal.show();
        },
        save() {
            if (this.option == "create"){
                axios.post('/admin/servicio-de-deuda', this.data).then(response => {
                    if(response && response.data.statusCode == 200){
                        this.addModal.hide();
                        this.reset();
                        this.getList();
                        this.messageAlert = response.data.message;        
                    }
                    else{
                        console.log(response);
                    }
                }).catch((error)=> {
                    if (error.response.status == 422){
                        this.errors = error.response.data.errors;
                    }else{
                        console.log(this.errors);
                    }
                });
            }
            else if (this.option == "update"){
                axios.put('/admin/servicio-de-deuda/'+this.data_id, this.data).then(response => {
                    if(response && response.data.statusCode == 200){
                        this.addModal.hide();
                        this.reset();
                        this.getList();
                        this.messageAlert = response.data.message;
                    }
                    else{
                        console.log(response);
                    }
                }).catch((error)=> {
                    if (error.response.status == 422){
                        this.errors = error.response.data.errors;
                    }else{
                        console.log(this.errors);
                    }
                });
            }
        },
        openModalEdit(data) {
            this.reset();
            this.option = "update";
            this.titleModal = "Editar";
            this.data_id = data.id;
            this.data_name = data.periodo;
            this.data.id = data.id;
            this.data.periodo = data.periodo;
            this.data.servicio_de_deuda = data.servicio_de_deuda;
            this.data.ingresos_de_libre_disposicion = data.ingresos_de_libre_disposicion;
            this.data.porcentaje = data.porcentaje;
            this.porcentajeColor=data.color;
            this.porcentajeValor =  parseFloat(this.data.servicio_de_deuda) / parseFloat(this.data.ingresos_de_libre_disposicion);
            this.addModal.show();
        },
        openModalDelete(data) {
            this.data_id = data.id;
            this.data_name = data.periodo;
            this.deleteModal.show();
        },

        openModalGrafica() {
            this.graficaModal.show();
            this.info_grafica = this.data_info;
            this.mostrarGrafica=1;
        },
        deleteRow(){
            axios.delete('/admin/servicio-de-deuda/'+this.data_id).then(response => {
                if(response && response.data.statusCode == 200){
                    this.deleteModal.hide();
                    this.reset();
                    this.getList();
                    this.messageAlert = response.data.message;
                }
                else{
                    console.log(response);
                }
            }).catch((error)=> {
                if (error.response.status == 422){
                    this.errors = error.response.data.errors;
                }else{
                    console.log(this.errors);
                }
            });
        },
        reset() {
            this.reiniciaMensajesError();
            this.data_id = "";
            this.data_name = "";
            this.data.id = null;
            this.data.periodo = null;
            this.data.servicio_de_deuda = null;
            this.data.ingresos_de_libre_disposicion = null;
            this.data.porcentaje = null;
            this.titleModal="";
            this.mostrarGrafica = 0;
            this.porcentajeColor="#fff";
            this.porcentajeValor=0;
        },

        reiniciaMensajesError(){
			for(let error in this.errors) {
				this.errors[error]="";
			}
		},
    },
  mounted() {
    this.getList();
    this.addModal = new bootstrap.Modal(
      document.getElementById("modal_add")
    );
    this.deleteModal = new bootstrap.Modal(
      document.getElementById("modal_delete")
    );
    this.graficaModal = new bootstrap.Modal(
      document.getElementById("modal_grafica")
    );
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
    });
  },
  created() {
    
    let self = this;
    this.$nextTick(() => {
      self.dataTable = $("#tableData").DataTable(self.datatablesConfig);
      new $.fn.dataTable.Buttons(self.dataTable, {
        name: "otherButton",
        buttons: [
          {
            className: "btn btn-secondary btn-sm border",
            text: '<i class="ri-file-add-line"></i>',
            titleAttr: "Agregar",
            exportOptions: {
              columns: ":visible",
            },
            action: function (e, dt, node, config) {
              self.openModalAdd(e.target.dataset.itemId);
            },
          },
          {
            className: "btn btn-secondary btn-sm border",
            text: '<i class="ri-line-chart-line"></i>',
            titleAttr: "Tendencia",
            exportOptions: {
              columns: ":visible",
            },
            action: function (e, dt, node, config) {
              self.openModalGrafica(e.target.dataset.itemId);
            },
          },
        ],
      });
      self.dataTable
        .buttons("otherButton", null)
        .containers()
        .addClass("groupleft")
        .insertBefore(".dataTables_filter");
    });
  },
  watch: {
    errors: function (newVal) {
      if (Object.values(newVal).length == 0) {
        this.addModal.hide();
        this.reset();
      }
    },
    datos() {
      let self = this;
      if (self.dataTable) {
        self.dataTable.destroy();
      }
      this.$nextTick(() => {
        self.dataTable = $("#tableData").DataTable(self.datatablesConfig);
        new $.fn.dataTable.Buttons(self.dataTable, {
          name: "otherButton",
          buttons: [
            {
              className: "btn btn-secondary btn-sm border",
              text: '<i class="ri-file-add-line"></i>',
              titleAttr: "Agregar",
              exportOptions: {
                columns: ":visible",
              },
              action: function (e, dt, node, config) {
                self.openModalAdd(e.target.dataset.itemId);
              },
            },
            {
            className: "btn btn-secondary btn-sm border",
            text: '<i class="ri-line-chart-line"></i>',
            titleAttr: "Tendencia",
            exportOptions: {
              columns: ":visible",
            },
            action: function (e, dt, node, config) {
              self.openModalGrafica(e.target.dataset.itemId);
            },
          },
          ],
        });
        self.dataTable
          .buttons("otherButton", null)
          .containers()
          .addClass("groupright")
          .insertBefore(".dataTables_filter");
      });
    },
  },
};
</script>
<style scoped>
  .small {
    font-size: smaller;
  }

  .btn-custom {
    display: inline-block;
    color: #fff;
    letter-spacing: 0;
    border-radius: 15px;
    padding: 2px 18px;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    text-decoration: none;
}
</style>

