<template>
    <div>
        <a v-for="categoria in categorias" :href="'/admin/indicador-por-categoria/'+categoria.id" :class="request=='/admin/indicador-por-categoria/'+categoria.id?'nav-link active':'nav-link'"  :key="categoria.id">
            <div class="sb-nav-link-icon"><i :class="'fas fa-'+categoria.icono"></i></div>
                {{categoria.descripcion}}
        </a>
    </div>
</template>
<script>
import axios from "axios";
export default {
    data() {
        return {
            categorias:[],
            request:"",
        }
    },
    methods: {
        getCategories(){
            axios.get('/admin/categorias').then(response=>{
                this.categorias = response.data.categorias;
            })
        },
    },
    mounted() {
        this.getCategories();
        this.request = window.location.pathname;

    },
    
}
</script>