<template>
    <div v-if="messageAlert">
        <Notification :message="messageAlert" @statusAlertMsg="cambiarMsgAlert" />
    </div>
    <div class="container mt-4">
        <h4>Lista de usuarios</h4>
        <div class="row">
            <div class="col-sm-12">
                <table class="table table-striped dt-responsive nowrap" id="users">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Nombre</th>
                            <th>Correo</th>
                            <th>Rol</th>
                            <th>Estatus</th>
                        </tr>
                    </thead>
                    <tfoot>
                        <tr>
                            <th></th>
                            <td>Nombre</td>
                            <td>Correo</td>
                            <th>Rol</th>
                            <th>Estatus</th>
                        </tr>
                    </tfoot>
                    <tbody>
                        <tr v-for="user in users" :key="user.id">
                            <td>
                                <a class="text-decoration-none me-2" @click="openModalEditUser(user)" href="#" title="Editar"><i class="ri-pencil-line"></i></a>
                                <a href="#" :class="user.status==0?'ri-lock-line':'ri-lock-unlock-line'" @click="onStatusUser(user)" :title="user.status==0?'Activar':'Desactivar'" style="text-decoration:none;"></a>
                                <a class="ms-2 text-decoration-none" @click="openModalDeleteUser(user)" href="#" title="Eliminar"> <i class="ri-delete-bin-line"></i></a>
                            </td>
                            <td>{{ user.name }}</td>
                            <td>{{ user.email }}</td>
                            <td v-for="rol in user.roles" :key="rol.id">
                                {{ rol.name }}
                            </td>
                            <td>{{ user.estatus }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modal_add_user" aria-labelledby="addUserModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="addUserModalLabel">{{titleModalUser}} usuario</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="reset()"></button>
                </div>
                <div class="modal-body">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <label for="name">Nombre: <span class="text-danger small">*</span></label>
                            <input @click="validateInput(1)" class="form-control" type="text" name="name" id="name" v-model="dataUser.name"/>
                            <small style="color:#FF6868;" v-for="(e_name, index) in errors.name" :key="index">{{e_name}}</small>
                        </div>
                        <div class="col-sm-6">
                            <label for="email">Correo electrónico: <span class="text-danger small">*</span></label>
                            <input @click="validateInput(2)" class="form-control" type="email" name="email" id="email" v-model="dataUser.email"/>
                            <small style="color:#FF6868;" v-for="(e_email, index) in errors.email" :key="index">{{e_email}}</small>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <label for="password">Contraseña: <span class="text-danger small">*</span></label>
                            <input @click="validateInput(3)" class="form-control" type="password" name="user_contrasena" id="user_contrasena" v-model="dataUser.password" v-on:keyup="inputKeydown"/>
                            <small style="color:#FF6868;" v-for="(e_password, index) in errors.password" :key="index">{{e_password}}</small>
                        </div>
                        <div class="col-sm-6">
                            <label for="cpassword">Confirmación contraseña: <span class="text-danger small">*</span></label>
                            <input @click="validateInput(4)" class="form-control" type="password" name="cpassword" id="cpassword" v-model="dataUser.cpassword"/>
                            <small style="color:#FF6868;" v-for="(e_cpassword, index) in errors.cpassword" :key="index">{{e_cpassword}}</small>
                        </div>
                        <div class="password-instructions mb-2">
                            <span class="small">Para definir tu contraseña, considera una longitud de al menos 8 caracteres: </span>
                            <div class="row">
                                <div class="col-6">
                                    <div id="password_mayus" >
                                        <i class="desactive_password ri-close-line" ref="password_mayus"></i>
                                        <span class="small">&nbsp;Una letra mayúscula</span>
                                    </div>
                                    <div id="password_minus">
                                        <i class="desactive_password ri-close-line" ref="password_minus"></i>
                                        <span class="small">&nbsp;Una letra minúscula</span>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div id="password_numer">
                                        <i class="desactive_password ri-close-line" ref="password_numer"></i>
                                        <span class="small">&nbsp;Un número</span>
                                    </div>
                                    <div id="password_carac">
                                        <i class="desactive_password ri-close-line" ref="password_carac"></i>
                                        <span class="small">&nbsp;Un carácter especial</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="row mb-2">
                    <div class="col-sm-12">
                        <label for="rol">Perfil: <span class="text-danger small">*</span></label>
                        <select @click="validateInput(5)" class="form-select" name="rol" id="rol" v-model="dataUser.rol">
                            <option disabled value="">Seleccione...</option>
                            <option v-for="rol in roles" :key="rol.id" :value="rol.id">
                                {{ rol.name }}
                            </option>
                        </select>
                        <small style="color:#FF6868;" v-for="(e_rol, index) in errors.rol" :key="index">{{e_rol}}</small>
                    </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <p class="small">
                            <span class="text-danger small">*</span> Datos requeridos
                            </p>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click="reset()" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                    <button @click="save()" type="button" class="btn btn-primary" v-text="option=='create'?'Guardar':'Guardar'"></button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modal_delete_user" tabindex="-1" role="dialog" aria-labelledby="deleteUserModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="deleteUserModalLabel">
                        Eliminar usuario
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click.prevent="reset()"></button>
                </div>
                <div class="modal-body">
                    <p>¿Deseas eliminar al usuario {{ user_name }}?</p>
                </div>
                <div class="modal-footer">
                    <button @click="reset()" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                    <button @click="deleteUser()" type="button" class="btn btn-danger">Eliminar</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="modal_banned_user" tabindex="-1" role="dialog" aria-labelledby="bannedUserModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="bannedUserModalLabel">
                        {{titleStatus}} usuario
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click.prevent="reset()"></button>
                </div>
                <div class="modal-body">
                    <p>¿Deseas {{titleStatus}} al usuario {{ user_name }}?</p>
                </div>
                <div class="modal-footer">
                    <button @click="reset()" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                    <button @click="chageStatus()" type="button" class="btn btn-danger">{{titleStatus}}</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import JSZip from "jszip";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
window.JSZip = JSZip;
import $ from "jquery";
import axios from "axios";
import Notification from "./Notification";
export default {
    inject: ["datatablesConfigUser"],
    components: {
        Notification,
    },
    data() {
        return {
            titleModalUser:"",
            titleStatus:"",
            users:[],
            roles:[],
            errors:[],
            dataUser: {
                id: null,
                name: null,
                email: null,
                password: null,
                cpassword: null,
                rol: null,
            },
            userModal: "",
            userDeleteModal:"",
            userBannedModal:"",
            option: "",
            user_id: "",
            user_name: "",
            user_status:1,
            dataTable: null,
            messageAlert:0,
        };
    },
    methods: {
        onStatusUser(value){
            this.userBannedModal.show();
            this.user_status = value.status;
            this.titleStatus =  value.status==1?'Desactivar':'Activar';
            this.user_id = value.id;
            this.user_name = value.name;
        },
        chageStatus(){
            axios.post('/admin/banned-user/'+this.user_id).then(response=>{
                this.messageAlert = response.data.message;
                this.userBannedModal.hide();
                this.reset();
                this.getList();
            });
        },
        cambiarMsgAlert(value){
            this.messageAlert= value;
        },
        inputKeydown(e){
            const pass = e.key;
            let mayus = new RegExp("^(?=.*[A-Z])");
            let minus = new RegExp("^(?=.*[a-z])");
            let num = new RegExp("^(?=.*[0-9])");
            let special = new RegExp("^(?=.*[-.!%&@#$^*?_~;,:|=¡¿+<>])");
            let upper = this.$refs.password_mayus;
            let lower = this.$refs.password_minus;
            let number = this.$refs.password_numer;
            let specialChar = this.$refs.password_carac; 
            const regExp = [mayus, minus, num, special];
            const elements = [upper, lower, number, specialChar];
            for (let i = 0; i < 4; i++) {                      
                elements[i].classList.remove('active_password','ri-check-line');
                elements[i].classList.add('desactive_password','ri-close-line');  
            }    
            let cadena = document.getElementById("user_contrasena").value;
            for (let x = 0; x< cadena.length; x++) {
                let caracter = cadena.charAt(x);
                for (let i = 0; i < 4; i++) {
                    if (pass && regExp[i].test(caracter)) {
                        elements[i].classList.remove('desactive_password', 'ri-close-line');
                        elements[i].classList.add('active_password', 'ri-check-line');  
                    }            
                }
            }   
        },
        getList(){
            axios.get('/admin/listusers').then(response=>{
                this.users = response.data.users;
                this.roles = response.data.roles;
            })
        },
        validateInput(value) {
            switch (value) {
                case 1:
                    this.errors.name = "";
                break;
                case 2:
                    this.errors.email = "";
                    break;
                case 3:
                    this.errors.password = "";
                    break;
                case 4:
                    this.errors.cpassword = "";
                    break;
                case 5:
                    this.errors.rol = "";
                    break;
            }
        },
        openModalAdd() {
            this.reset();
            this.option = "create";
            this.titleModalUser = "Agregar";
            this.userModal.show();
        },
        save() {
            if (this.option == "create"){
                axios.post('/admin/usuarios', this.dataUser).then(response => {
                    if(response && response.data.statusCode == 200){
                        this.messageAlert = response.data.message;
                        this.reset();
                         this.userModal.hide();
                        this.getList();
                    }
                    else{
                        console.log(response);
                    }
                }).catch((error)=> {
                    if (error.response.status == 422){
                        this.errors = error.response.data.errors;
                    }else{
                        console.log(this.errors);
                    }
                });
            }
            else if (this.option == "update"){
                axios.put('/admin/usuarios/'+this.user_id, this.dataUser).then(response => {
                    if(response && response.data.statusCode == 200){
                        this.messageAlert = response.data.message;
                        this.reset();
                         this.userModal.hide();
                        this.getList();
                    }
                    else{
                        console.log(response);
                    }
                }).catch((error)=> {
                    if (error.response.status == 422){
                        this.errors = error.response.data.errors;
                    }else{
                        console.log(this.errors);
                    }
                });
            }
        },
        openModalEditUser(data) {
            this.reset();
            this.option = "update";
            this.titleModalUser = "Editar";
            this.user_id = data.id;
            this.dataUser.id = data.id;
            this.dataUser.name = data.name;
            this.dataUser.email = data.email;
            this.dataUser.rol = data.roles[0].id;
            this.userModal.show();
        },
        openModalDeleteUser(data) {
            this.user_id = data.id;
            this.user_name = data.name;
            this.userDeleteModal.show();
        },
        deleteUser(){
            axios.delete('/admin/usuarios/'+this.user_id).then(response => {
                if(response && response.data.statusCode == 200){
                    this.messageAlert = response.data.message;
                    this.reset();
                    this.userDeleteModal.hide();
                    this.getList();
                }
                else{
                    console.log(response);
                }
            }).catch((error)=> {
                if (error.response.status == 422){
                    this.errors = error.response.data.errors;
                }else{
                    console.log(this.errors);
                }
            });
        },
        reset() {
            this.user_id = "";
            this.user_name = "";
            this.dataUser.id = null;
            this.dataUser.name = null;
            this.dataUser.email = null;
            this.dataUser.password = null;
            this.dataUser.cpassword = null;
            this.dataUser.rol = null;
            this.titleModalUser="";
        },
  },
  mounted() {
    this.getList();
    this.userModal = new bootstrap.Modal(
      document.getElementById("modal_add_user")
    );
    this.userDeleteModal = new bootstrap.Modal(
      document.getElementById("modal_delete_user")
    );
    this.userBannedModal = new bootstrap.Modal(
      document.getElementById("modal_banned_user")
    );
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
    });
  },
  created() {
    let self = this;
    this.$nextTick(() => {
      self.dataTable = $("#users").DataTable(self.datatablesConfigUser);
      new $.fn.dataTable.Buttons(self.dataTable, {
        name: "otherButton",
        buttons: [
          {
            className: "btn btn-secondary btn-sm border",
            text: '<i class="ri-user-add-line"></i>',
            titleAttr: "Agregar",
            exportOptions: {
              columns: ":visible",
            },
            action: function (e, dt, node, config) {
              self.openModalAdd(e.target.dataset.itemId);
            },
          },
        ],
      });
      self.dataTable
        .buttons("otherButton", null)
        .containers()
        .addClass("groupleft")
        .insertBefore(".dataTables_filter");
    });
  },
  watch: {
    errors: function (newVal) {
      if (Object.values(newVal).length == 0) {
        this.userModal.hide();
        this.reset();
      }
    },
    users() {
      let self = this;
      if (self.dataTable) {
        self.dataTable.destroy();
      }
      this.$nextTick(() => {
        self.dataTable = $("#users").DataTable(self.datatablesConfigUser);
        new $.fn.dataTable.Buttons(self.dataTable, {
          name: "otherButton",
          buttons: [
            {
              className: "btn btn-secondary btn-sm border",
              text: '<i class="ri-user-add-line"></i>',
              titleAttr: "Agregar",
              exportOptions: {
                columns: ":visible",
              },
              action: function (e, dt, node, config) {
                self.openModalAdd(e.target.dataset.itemId);
              },
            },
          ],
        });
        self.dataTable
          .buttons("otherButton", null)
          .containers()
          .addClass("groupright")
          .insertBefore(".dataTables_filter");
      });
    },
  },
};
</script>
<style scoped>
  .small {
    font-size: smaller;
  }
  .active_password{
      font-size: smaller;
      color: #33FFA9;
  }
  .desactive_password{
    font-size: smaller;
    color: #C4C4C4;
    transition: all 0.3s;
  }
</style>

