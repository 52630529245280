//require('./bootstrap');
import { createApp } from 'vue';
import CKEditor from '@ckeditor/ckeditor5-vue';
import 'remixicon/fonts/remixicon.css'

import LoginComponent from './components/auth/LoginComponent';
import UserComponent from './components/admin/UserComponent';
import DashboardComponent from './components/admin/DashboardComponent';
import ListaIndicadoresComponent from './components/admin/ListaIndicadoresComponent';

//FINANZAS PUBLICAS
import DeudaTotalComponent from './components/admin/DeudaTotalComponent';
import DeudaPorFinanciamientoComponent from './components/admin/DeudaPorFinanciamientoComponent';
import PasivoCortoPlazoComponent from './components/admin/PasivoCortoPlazoComponent';
import ServicioDeDeudaComponent from './components/admin/ServicioDeDeudaComponent';
import BalanceFinancieroComponent from './components/admin/BalanceFinancieroComponent';
import GastoCorrienteComponent from './components/admin/GastoCorrienteComponent';
import GastoDeCapitalComponent from './components/admin/GastoDeCapitalComponent';
import GastoServicioPersonalSubrogacionComponent from './components/admin/GastoServicioPersonalSubrogacionComponent';
import IngresoPropioComponent from './components/admin/IngresoPropioComponent';
import ParticipacionFederalComponent from './components/admin/ParticipacionFederalComponent';
import MenuCategoriasComponent from './components/admin/MenuCategoriasComponent';

//SEGURIDAD PUBLICA
import IncidenciaDelictivaFueroComunComponent from './components/admin/IncidenciaDelictivaFueroComunComponent';
import PercepcionComponent from './components/admin/PercepcionComponent';



/*import DanoPropiedadComponent from './components/admin/DanoPropiedadComponent';
import ExtorsionComponent from './components/admin/ExtorsionComponent';
import FraudeComponent from './components/admin/FraudeComponent';
import HomicidioCulposoComponent from './components/admin/HomicidioCulposoComponent';
import HomicidioDolosoComponent from './components/admin/HomicidioDolosoComponent';
import LesionesCulposasComponent from './components/admin/LesionesCulposasComponent';
import NarcomenudeoComponent from './components/admin/NarcomenudeoComponent';
import RoboCasaHabitacionComponent from './components/admin/RoboCasaHabitacionComponent';
import RoboNegocioComponent from './components/admin/RoboNegocioComponent';
import RoboTranseunteComponent from './components/admin/RoboTranseunteComponent';
import RoboVehiculoAutomotorComponent from './components/admin/RoboVehiculoAutomotorComponent';
import OtrosRobosComponent from './components/admin/OtrosRobosComponent';
import ViolacionComponent from './components/admin/ViolacionComponent';
import ViolenciaFamiliarComponent from './components/admin/ViolenciaFamiliarComponent';
import OtrosDelitosComponent from './components/admin/OtrosDelitosComponent';*/


var languageDatatable = {
    "processing": "Procesando...",
    "lengthMenu": "Mostrar _MENU_ registros",
    "zeroRecords": "No se encontraron resultados",
    "emptyTable": "Ningún dato disponible en esta tabla",
    "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
    "infoFiltered": "(filtrado de un total de _MAX_ registros)",
    "search": "Buscar:",
    "infoThousands": ",",
    "loadingRecords": "Cargando...",
    "paginate": {
        "first": "Primero",
        "last": "Último",
        "next": "Siguiente",
        "previous": "Anterior"
    },
    "aria": {
        "sortAscending": ": Activar para ordenar la columna de manera ascendente",
        "sortDescending": ": Activar para ordenar la columna de manera descendente"
    },
    "buttons": {
        "copy": "Copiar",
        "colvis": "Visibilidad",
        "collection": "Colección",
        "colvisRestore": "Restaurar visibilidad",
        "copyKeys": "Presione ctrl o u2318 + C para copiar los datos de la tabla al portapapeles del sistema. <br \/> <br \/> Para cancelar, haga clic en este mensaje o presione escape.",
        "copySuccess": {
            "1": "Copiada 1 fila al portapapeles",
            "_": "Copiadas %ds fila al portapapeles"
        },
        "copyTitle": "Copiar al portapapeles",
        "csv": "CSV",
        "excel": "Excel",
        "pageLength": {
            "-1": "Mostrar todas las filas",
            "_": "Mostrar %d filas"
        },
        "pdf": "PDF",
        "print": "Imprimir",
        "renameState": "Cambiar nombre",
        "updateState": "Actualizar"
    },
    "autoFill": {
        "cancel": "Cancelar",
        "fill": "Rellene todas las celdas con <i>%d<\/i>",
        "fillHorizontal": "Rellenar celdas horizontalmente",
        "fillVertical": "Rellenar celdas verticalmentemente"
    },
    "decimal": ".",
    "searchBuilder": {
        "add": "Añadir condición",
        "button": {
            "0": "Constructor de búsqueda",
            "_": "Constructor de búsqueda (%d)"
        },
        "clearAll": "Borrar todo",
        "condition": "Condición",
        "conditions": {
            "date": {
                "after": "Despues",
                "before": "Antes",
                "between": "Entre",
                "empty": "Vacío",
                "equals": "Igual a",
                "notBetween": "No entre",
                "notEmpty": "No Vacio",
                "not": "Diferente de"
            },
            "number": {
                "between": "Entre",
                "empty": "Vacio",
                "equals": "Igual a",
                "gt": "Mayor a",
                "gte": "Mayor o igual a",
                "lt": "Menor que",
                "lte": "Menor o igual que",
                "notBetween": "No entre",
                "notEmpty": "No vacío",
                "not": "Diferente de"
            },
            "string": {
                "contains": "Contiene",
                "empty": "Vacío",
                "endsWith": "Termina en",
                "equals": "Igual a",
                "notEmpty": "No Vacio",
                "startsWith": "Empieza con",
                "not": "Diferente de",
                "notContains": "No Contiene",
                "notStarts": "No empieza con",
                "notEnds": "No termina con"
            },
            "array": {
                "not": "Diferente de",
                "equals": "Igual",
                "empty": "Vacío",
                "contains": "Contiene",
                "notEmpty": "No Vacío",
                "without": "Sin"
            }
        },
        "data": "Data",
        "deleteTitle": "Eliminar regla de filtrado",
        "leftTitle": "Criterios anulados",
        "logicAnd": "Y",
        "logicOr": "O",
        "rightTitle": "Criterios de sangría",
        "title": {
            "0": "Constructor de búsqueda",
            "_": "Constructor de búsqueda (%d)"
        },
        "value": "Valor"
    },
    "searchPanes": {
        "clearMessage": "Borrar todo",
        "collapse": {
            "0": "Paneles de búsqueda",
            "_": "Paneles de búsqueda (%d)"
        },
        "count": "{total}",
        "countFiltered": "{shown} ({total})",
        "emptyPanes": "Sin paneles de búsqueda",
        "loadMessage": "Cargando paneles de búsqueda",
        "title": "Filtros Activos - %d",
        "showMessage": "Mostrar Todo",
        "collapseMessage": "Colapsar Todo"
    },
    "select": {
        "cells": {
            "1": "1 celda seleccionada",
            "_": "%d celdas seleccionadas"
        },
        "columns": {
            "1": "1 columna seleccionada",
            "_": "%d columnas seleccionadas"
        },
        "rows": {
            "1": "1 fila seleccionada",
            "_": "%d filas seleccionadas"
        }
    },
    "thousands": ",",
    "datetime": {
        "previous": "Anterior",
        "next": "Proximo",
        "hours": "Horas",
        "minutes": "Minutos",
        "seconds": "Segundos",
        "unknown": "-",
        "amPm": [
            "AM",
            "PM"
        ],
        "months": {
            "0": "Enero",
            "1": "Febrero",
            "10": "Noviembre",
            "11": "Diciembre",
            "2": "Marzo",
            "3": "Abril",
            "4": "Mayo",
            "5": "Junio",
            "6": "Julio",
            "7": "Agosto",
            "8": "Septiembre",
            "9": "Octubre"
        },
        "weekdays": [
            "Dom",
            "Lun",
            "Mar",
            "Mie",
            "Jue",
            "Vie",
            "Sab"
        ]
    },
    "editor": {
        "close": "Cerrar",
        "create": {
            "button": "Nuevo",
            "title": "Crear Nuevo Registro",
            "submit": "Crear"
        },
        "edit": {
            "button": "Editar",
            "title": "Editar Registro",
            "submit": "Actualizar"
        },
        "remove": {
            "button": "Eliminar",
            "title": "Eliminar Registro",
            "submit": "Eliminar",
            "confirm": {
                "_": "¿Está seguro que desea eliminar %d filas?",
                "1": "¿Está seguro que desea eliminar 1 fila?"
            }
        },
        "error": {
            "system": "Ha ocurrido un error en el sistema (<a target=\"\\\" rel=\"\\ nofollow\" href=\"\\\">Más información&lt;\\\/a&gt;).<\/a>"
        },
        "multi": {
            "title": "Múltiples Valores",
            "info": "Los elementos seleccionados contienen diferentes valores para este registro. Para editar y establecer todos los elementos de este registro con el mismo valor, hacer click o tap aquí, de lo contrario conservarán sus valores individuales.",
            "restore": "Deshacer Cambios",
            "noMulti": "Este registro puede ser editado individualmente, pero no como parte de un grupo."
        }
    },
    "info": "Mostrando _START_ a _END_ de _TOTAL_ registros",
    "stateRestore": {
        "creationModal": {
            "button": "Crear",
            "name": "Nombre:",
            "order": "Clasificación",
            "paging": "Paginación",
            "search": "Busqueda",
            "select": "Seleccionar"
        },
        "emptyError": "El nombre no puede estar vacio",
        "removeConfirm": "¿Seguro que quiere eliminar este %s?",
        "removeError": "Error al eliminar el registro",
        "removeJoiner": "y",
        "removeSubmit": "Eliminar",
        "renameButton": "Cambiar Nombre",
        "renameLabel": "Nuevo nombre para %s"
    }
}

var datatablesConfig = {
    language: languageDatatable,
    responsive: true,
    dom: "B<'row mb-2'><'row'<'col-md-6'l><'col-md-6'f>r>t<'row'<'col-md-4'i>><'row'<'#colvis'>p>",
    columnDefs: [
        { visible: false, targets: 5 }
    ],
    buttons: [
        {
            extend: 'copy',
            className: 'btn btn-secondary btn-sm border',
            text: '<i class="ri-file-copy-line"></i>',
            titleAttr: 'Copiar',
            exportOptions: {
                columns: [1, 2, 3, 5],
            }
        },
        {
            extend: 'excelHtml5',
            className: 'btn btn-secondary btn-sm border',
            text: '<i class="ri-file-excel-line"></i>',
            titleAttr: 'Excel',
            exportOptions: {
                columns: [1, 2, 3, 5],
                format: {
                    body: function (data, row, column, node) {
                        return column === 1 || column === 2 || column === 3 ?
                            data.replace(/[$&nbsp;]/g, '') :
                            data;
                    }
                }
            }
        },
        {
            extend: 'pdf',
            className: 'btn btn-secondary btn-sm border',
            text: '<i class="ri-file-pdf-line"></i>',
            titleAttr: 'PDF',
            exportOptions: {
                columns: ':visible'
            }
        },
        {
            extend: 'print',
            className: 'btn btn-secondary btn-sm border',
            text: '<i class="ri-printer-line"></i>',
            titleAttr: 'Imprimir',
            exportOptions: {
                columns: ':visible'
            }
        },
    ]
}

var datatablesConfigUser = {
    language: languageDatatable,
    dom: "B<'row mb-2'><'row'<'col-md-6'l><'col-md-6'f>r>t<'row'<'col-md-4'i>><'row'<'#colvis'>p>",
    buttons: [
        {
            extend: 'copy',
            className: 'btn btn-info border',
            text: '<i class="ri-file-copy-line"></i>',
            titleAttr: 'Copiar',
            exportOptions: {
                columns: ':visible'
            }
        },
        {
            extend: 'excel',
            className: 'btn btn-info border',
            text: '<i class="ri-file-excel-line"></i>',
            titleAttr: 'Excel',
            exportOptions: {
                columns: ':visible'
            }
        },
        {
            extend: 'pdf',
            className: 'btn btn-info border',
            text: '<i class="ri-file-pdf-line"></i>',
            titleAttr: 'PDF',
            exportOptions: {
                columns: ':visible'
            }
        },
        {
            extend: 'print',
            className: 'btn btn-info border',
            text: '<i class="ri-printer-line"></i>',
            titleAttr: 'Imprimir',
            exportOptions: {
                columns: ':visible'
            }
        },
    ]
}

const app = createApp({
    components: {
        LoginComponent,
        DashboardComponent,
        UserComponent,
        ListaIndicadoresComponent,
        DeudaTotalComponent,
        DeudaPorFinanciamientoComponent,
        PasivoCortoPlazoComponent,
        ServicioDeDeudaComponent,
        BalanceFinancieroComponent,
        GastoCorrienteComponent,
        GastoDeCapitalComponent,
        GastoServicioPersonalSubrogacionComponent,
        IngresoPropioComponent,
        ParticipacionFederalComponent,
        MenuCategoriasComponent,
        IncidenciaDelictivaFueroComunComponent,
        PercepcionComponent
        /*DanoPropiedadComponent,
        ExtorsionComponent,
        FraudeComponent,
        HomicidioCulposoComponent,
        HomicidioDolosoComponent,
        LesionesCulposasComponent,
        NarcomenudeoComponent,
        RoboCasaHabitacionComponent,
        RoboNegocioComponent,
        RoboTranseunteComponent,
        RoboVehiculoAutomotorComponent,
        OtrosRobosComponent,
        ViolacionComponent,
        ViolenciaFamiliarComponent,
        OtrosDelitosComponent*/
    },
    provide: {
        'datatablesConfig': datatablesConfig,
        'datatablesConfigUser': datatablesConfigUser,
    }
})
app.use(CKEditor).mount('#app');

