<template>
    <div class="container">
      <section class="section register d-flex flex-column align-items-center justify-content-center">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center py-5">
                <img src="img/logo_blanco_01.png" alt="">
            </div>
          </div>
        </div>
      </section>
    </div>
</template>